.contentsTitGroup {margin-bottom:30px; position:relative;}
.contentsTitGroup .title {height:40px;}
.contentsTitGroup .title > h3 {height: 40px; font-size: 24px; font-weight: bold; line-height: 1.67; letter-spacing: -0.5px; color: #000000;}
.contentsTitGroup .pagenation {width:80px; height:40px; position:absolute; right:0; top:0;}
.contentsTitGroup .pagenation:after {content:""; display:block; clear:both;}
.contentsTitGroup .pagenation .btnPrev {width:40px; height:40px; font-size:0; background:url(/images/icon/btn-l-arrow-left.svg) center no-repeat; float:left;}
.contentsTitGroup .pagenation .btnNext {width:40px; height:40px; font-size:0; background:url(/images/icon/btn-l-arrow-right.svg) center no-repeat; float:left;}
.contentsContGroup {}
.contentsContGroup .swiper-container {}
.swiper-container {}
.swiper-container .swiper-wrapper {}
.swiper-container .swiper-slide {min-width:212px;}
.swiper-container .swiper-slide:last-child {margin-right:0;}

@media screen and (min-width: 100px) and (max-width: 1279px) {
    /* .contentsTitGroup .title > h3 {padding:0 16px;} */
    /* .contentsTitGroup .pagenation {right:16px;} */
}
@media screen and (min-width: 100px) and (max-width: 1023px) {
    .contentsTitGroup {
        margin-bottom:16px; 
        /* padding:0 20px; */
    }
    .contentsTitGroup .title {height:32px;}
    .contentsTitGroup .title > h3 {height: 32px; padding:0; font-size: 18px; font-weight: bold; line-height: 1.78; letter-spacing: -0.38px;}
    .contentsTitGroup .pagenation {display:none;}
    .swiper-container {padding-left:20px;}
    .swiper-container .swiper-slide {width:212px !important;}
}