/* live.k 및 출결관리 */
.lyMinBtn {display:flex; justify-content:flex-end; position:fixed; bottom:110px; right:20px; z-index:2000;}
.lyMinBtn a {display:block; width:70px; height:70px; padding-top:39px; background:#f8f8f8; border-radius:50%; box-shadow:0 3px 4px 0 rgba(0, 0, 0, 0.32); color:#fff; text-align:center;}
.lyMinBtn a + a {margin-left:20px;}

.lyMinBtn .mb_live {background:#ff647c url(/images/icon/icon_livek.svg) calc(100% - 18px) 10px no-repeat; background-size:27px auto; font-size:12px;}
.lyMinBtn .mb_attend {margin-left:5px; background:#7f56fd url(/images/icon/icon_qrcode.svg) center 13px no-repeat; font-size:12px;}

.lytFooterM {display:none;}
@media screen and (min-width: 100px) and (max-width: 1023px) {
    .lytFooterM {width:100%; display:block;}
    .lytFooterM .footerArea {width:100%; height:90px; margin-top:45px;}
    .lytFooterM .footerMenu {width:100%; height:90px; background:#fff; position:fixed; left:0; bottom:0; z-index: 1001;}
    .lytFooterM .footerMenu:after {content:""; width:134px; height:5px; border-radius:2.5px; background:#000; position:fixed; left:calc(50% - 67px); bottom:9px;}
    /* .lytFooterM .footerMenu .menuGroup {width:100%; padding:9px 5px 27px; font-size:0;} */
    .lytFooterM .menuGroup {display:flex; align-items:center; justify-content:space-around;}
    /* .lytFooterM .footerMenu .menuList {width:64px; height:54px; margin-left:calc(25% - 80px); font-size:0; text-align:center; display:inline-block;}
    .lytFooterM .footerMenu .menuList:first-child {margin-left:0;} */
    .lytFooterM .footerMenu .menuList {width:auto; min-width:20px; margin-left:0;}
    .lytFooterM .footerMenu .menuList a {width:100%; height:100%; padding-top:34px; font-size: 12px; font-weight: normal; line-height: normal; letter-spacing: -0.16px; text-align: center; color: #2a2a2a; display:inline-block;}
    .lytFooterM .footerMenu .menuList.home a {background:url(/images/icon/home-1391.svg) center 8px no-repeat;}
    .lytFooterM .footerMenu .menuList.search a {background:url(/images/icon/ico-24-search.svg) center 5px no-repeat;}
    .lytFooterM .footerMenu .menuList.study a {background:url(/images/icon/book-1206.svg) center 8px no-repeat;}
    .lytFooterM .footerMenu .menuList.subscrip a {background:url(/images/icon/database-system-1797.svg) center 8px no-repeat;}
    .lytFooterM .footerMenu .menuList.my a {background:url(/images/icon/shape.svg) center 8px no-repeat;}
    .lytFooterM .footerMenu .menuList.qr a {background:url(/images/icon/icon_qrcode_black.svg) center 8px no-repeat; background-size:21px auto;}
}

/* more layout */
.lytMainMore {width:100%; margin:120px 0;}
.lytMainMore .moreArea {width:100%; max-width:1384px; margin:0 auto;}
.lytMainMore .moreTitGroup {margin-bottom:16px; position:relative;}
.lytMainMore .moreTitGroup .title {height:40px;}
.lytMainMore .moreTitGroup .title > h3 {height: 40px; font-size: 24px; font-weight: bold; line-height: 1.67; letter-spacing: -0.5px; color: #7c7c7c;}
.lytMainMore .moreContGroup {}
.lytMainMore .moreContGroup .menuGroup {}
.lytMainMore .moreContGroup .menuList {margin-bottom:16px;}
.lytMainMore .moreContGroup .menuList h4 {width:100%; height:56px;}
.lytMainMore .moreContGroup .menuList a, .lytMainMore .moreContGroup .menuList button {width:100%; height:100%; font-size: 38px; font-weight: bold; line-height: 1.47; letter-spacing: -0.5px; color: #000000; display:block; text-align:left;}
.lytMainMore .moreContGroup .menuList a:hover, .lytMainMore .moreContGroup .menuList button:hover {color:#3c89f9;}

@media screen and (min-width: 100px) and (max-width: 1279px) {}
@media screen and (min-width: 100px) and (max-width: 1023px) {
    .lytMainMore {width:100%; margin:50px 0;}
    .lytMainMore .moreArea {
        max-width:none; 
        /* padding:0 20px; */
    }
    .lytMainMore .moreTitGroup {margin-bottom:8px;}
    .lytMainMore .moreTitGroup .title {height:32px;}
    .lytMainMore .moreTitGroup .title > h3 {height: 32px; font-size: 18px; line-height: 1.78; letter-spacing: -0.38px;}

    .lytMainMore .moreContGroup .menuList {height:50px; margin-bottom:0px;}
    .lytMainMore .moreContGroup .menuList h4 {width:100%; height:50px; background:url(/images/icon/ico-24-arrow-right.svg) right center no-repeat;}
    .lytMainMore .moreContGroup .menuList a, .lytMainMore .moreContGroup .menuList button {width:100%; height:100%; padding:5px 0; font-size: 24px; line-height: 1.67;}
}

.qrBtn {display: none;}

@media screen and (min-width: 100px) and (max-width: 1279px) {}
@media screen and (min-width: 100px) and (max-width: 1023px) {
    .qrBtn {
        display: block;
        padding: unset;
        position: absolute;
        top: -15px;
        transform: translateY(-100%);
        width: 75px;
        height: 75px;
        background: #7f56fd;
        right: 15px;
        border-radius: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: white;
        box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.2);
        position: fixed;
        z-index: 99;
        top: calc(100% - 105px);
      }
    .attendanceItem { font-size: 24px; gap: 50px; height: 100%; align-content: center; }  
    .attendanceItem > .item { height: fit-content; justify-content: center; align-items: center; flex-direction: column; display: flex; flex-wrap: wrap;}  
    .attendanceItem img { width: 200px; }
    .attendanceItem .buttonWrap { width: 100%; display: flex; justify-content: center; gap: 20px; }
}
