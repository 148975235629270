.ebookTitle {
    font-size: 24px;
    font-weight : bold;
    width: 100%;
}

.ebookWrap {
    height: 620px;
}
.ebookWrapMain {
    height: 620px;
    top: 70px;
}


@media screen and (min-width: 100px) and (max-width: 1023px) {
    .ebookTitle {
        display: none;
    }
    .ebookWrap {
        height: 390px;
    }
    .ebookWrapMain {
        top: 0px;
        height: 390px;
    }

}