/* ------------------------------ 팝업 ------------------------------ */
.modPopup {width:100%; height:100%; display:none; position:fixed; left:0; top:0; z-index:1000;}
.modPopup.on {display:block;}

.modPopup .popupDim {width:100%; height:100%; font-size:0; background:rgba(0,0,0,0.4); position:fixed; left:0; top:0;}
.modPopup.on .popupDim {opacity:0; animation: popOpenDimd 0.2s linear forwards;}
@keyframes popOpenDimd { 0% {opacity:0;} 100% {opacity:1;} }

.modPopup .popupArea {max-height: calc(100% - 100px);position:absolute; right:50%; bottom:50%; transform: translate(50.6%,50.6%);}

.modPopup .popupInMotion {width:100%; height:100%; background:#fff;}
.modPopup.on .popupInMotion {opacity:0; transform:scale(0.7); animation: popOpenScale 0.3s linear forwards;}
@keyframes popOpenScale { 0% {opacity:0; transform:scale(0.7);} 100% {opacity:1; transform:scale(1);} }


.modPopup .popupTitGroup {width:100%; height:105px; border-bottom: solid 1px #e6e6e6; position:relative;}
.modPopup .popupTitGroup .title {width:100%; padding:34px 70px; font-size: 24px; font-weight: 500; letter-spacing: -0.6px; text-align: center; color: #333333;}
.modPopup .popupTitGroup .btnPopRefresh {height: 24px; padding-left:28px; font-size: 16px; font-weight: 500; line-height: normal; letter-spacing: 0.5px; color: #000000; background:url(/images/icon/ico-24-reset.svg) left center no-repeat; background-size:24px; position:absolute; left:42px; top:40px;}
.modPopup .popupTitGroup .btnPopClose {width:24px; height:24px; font-size:0; background:url(/images/icon/ico-24-close.svg) center no-repeat; position:absolute; right:42px; top:40px;}
.modPopup .popupContGroup {height: calc(100% - 105px); padding:40px; overflow:auto;}
.modPopup .popupContGroup.noPd {padding:0;}

@media screen and (min-width: 100px) and (max-width: 1279px) {}
@media screen and (min-width: 100px) and (max-width: 1023px) {
	.modPopup .popupDim {display:none;}
	.modPopup .popupArea {width:100% !important; height:100% !important; max-height: none; left:0; right:auto; top:0; transform: translate(0,0);}
	.modPopup .popupTitGroup {height:60px;}
	.modPopup .popupTitGroup .title {padding:14px 70px; font-size: 18px; font-weight: 700; line-height: 1.78; letter-spacing: -0.38px; color: #000000;}
	.modPopup .popupTitGroup .btnPopRefresh {height: 24px; padding-left:26px; font-size: 14px; line-height:24px; left:20px; top:18px;}
	.modPopup .popupTitGroup .btnPopClose {background:url(/images/icon/ico-24-delete.svg) center no-repeat; right:20px; top:18px;}
	.modPopup .popupContGroup {height: calc(100% - 60px); padding:30px 20px; overflow:auto;}
	
	/* 모바일 > 딤팝업 */
	.modPopup.modal {z-index:1002;}
	.modPopup.modal .popupDim {display:block;}
	.modPopup.modal .popupArea {width:calc(100% - 40px) !important; height:auto !important; position:absolute; left:auto; top:auto; right:50%; bottom:50%; transform: translate(50%,50%);}
    

	/* 모바일 > 사이드팝업 */
	.modPopup.side {display:block; visibility: hidden; transition:0.5s;}
	.modPopup.side.on {display:block; visibility: visible; }
	.modPopup.side.on .popupDim {display:block;}
	.modPopup.side .popupArea {width: 82% !important; height: 100%; max-height:none; padding: 20px 20px; background: #fff; position: absolute; left:auto; right:-100%; top: 0; transition:right 0.5s;}
	.modPopup.side.on .popupArea {right:0;}

	.modPopup.side.on .popupInMotion {opacity:1; transform:scale(1); animation:none;}

	.modPopup.side .popupTitGroup {height:60px;  border-bottom:2px solid #060606;}
	.modPopup.side .popupTitGroup .title {padding:22px 0 0 0; font-size: 18px; font-weight: bold; line-height: 1.78; letter-spacing: -0.38px; color: #000000; text-align:left;}
	.modPopup.side .popupTitGroup .btnPopClose {background:url(/images/icon/ico-24-delete.svg) center no-repeat; right:0px; top:22px;}
	.modPopup.side .popupContGroup {height: calc(100% - 60px); padding:0; overflow:auto;}
	/* 모바일 > 풀팝업 */
	.modPopup.full {}
  .modPopup.full .popupContGroup {padding-bottom:0;}
  .modPopup.full .popupContGroup:after {content:""; display:block; width:100%; padding-bottom:90px;}
}

.lytPwChange {width:100%;}
.lytPwChange .pwChangeArea {width:100%;}
.lytPwChange .pwChangeCont {width:100%; padding:40px;}
.lytPwChange .pwChangeCont .message {width:100%; margin-bottom:40px; text-align:center; font-size: 18px; font-weight: 500; line-height: 1.78; letter-spacing: -0.38px; color: #000000;}
.lytPwChange .pwChangeCont .message .tRed {color:#ff647c;}
.lytPwChange .pwChangeCont .btnPwChange {width:100%; height:50px; border: solid 1px #e5e5e5; font-size: 16px; font-weight: 500; line-height: normal; letter-spacing: normal; text-align: center; color: #7f7f7f;}
.lytPwChange .pwChangeCont .btnPwChange:hover {border: solid 1px #b2b2b2; color: #4a4a4a;}
.lytPwChange .pwChangeBtn {width:100%; font-size:0;  background:#b2b2b2;}
.lytPwChange .pwChangeBtn .noView {width:50%; height:64px; font-size: 18px; font-weight: bold; line-height: 1.78; letter-spacing: -0.38px; text-align: center; color: #ffffff; background:#b2b2b2;}
.lytPwChange .pwChangeBtn .close {width:50%; height:64px; font-size: 18px; font-weight: bold; line-height: 1.78; letter-spacing: -0.38px; text-align: center; color: #ffffff; background:#7f7f7f;}
@media screen and (min-width: 100px) and (max-width: 1023px) {
    .lytPwChange .pwChangeCont {width:100%; padding:30px 20px 40px 20px;}
    .lytPwChange .pwChangeCont .message {width:100%; margin-bottom:30px; font-size: 14px; line-height: 1.71; letter-spacing: normal;}
    .lytPwChange .pwChangeCont .btnPwChange {font-size: 14px;}
    .lytPwChange .pwChangeBtn {width:100%; font-size:0;  background:#b2b2b2;}
    .lytPwChange .pwChangeBtn .noView {width:50%; height:50px; font-size: 14px; font-weight: 500; line-height: normal; letter-spacing: normal;}
    .lytPwChange .pwChangeBtn .close {width:50%; height:50px; font-size: 14px; font-weight: 500; line-height: normal; letter-spacing: normal;}
}