
/* forum full card item */
.cptForumFull {width:100%; min-width:212px;}
.cptForumFull .thumLink {width:100%; height:100%; display:block;}
.cptForumFull .thumImgGroup {width:100%; background:#f2f2f2; position:relative;}
.cptForumFull .thumImgGroup .imgBox {width:100%; font-size: 0;}
.cptForumFull .thumImgGroup .imgBox img {width:100%;}
.cptForumFull .thumImgGroup .imgIcon {width:64px; height:64px; position:absolute; left:20px; top:24px;}
.cptForumFull .thumImgGroup .imgIcon img {width:100%;}
.cptForumFull .thumImgGroup .imgInfo {max-width:200px; position:absolute; right:30px; top:24px;}
.cptForumFull .thumImgGroup .imgInfo h4 {font-size: 24px; font-weight: 500; line-height: 1.67; letter-spacing: -0.5px; }

.cptForumFull .thumContGroup {width:100%;}
.cptForumFull .thumTitle {height: 32px; margin-top:16px;}
.cptForumFull .thumTitle h4 {width: 100%; height: 32px; font-size: 18px; font-weight: bold; line-height: 1.78; letter-spacing: -0.37px; color: #000000; display: block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.cptForumFull .thumDate {margin-top:4px;}
.cptForumFull .thumDate .schedule {font-size:0;}
.cptForumFull .thumDate .schedule > dt {height:24px; margin-right:8px; font-size: 14px; line-height: 1.71; letter-spacing: -0.29px; color: #7c7c7c; display:inline-block;}
.cptForumFull .thumDate .schedule > dd {height:24px; font-family: Rubik; font-size: 14px; line-height: 1.71; letter-spacing: -0.29px; color: #7c7c7c; display:inline-block;}
.cptForumFull .thumDate .schedule > dd span {font-family: Rubik;}

@media screen and (min-width: 100px) and (max-width: 1279px) {}
@media screen and (min-width: 100px) and (max-width: 1023px) {
    .cptForumFull .thumImgGroup .imgIcon {width:24px; height:24px; left:10px; top:10px;}
    .cptForumFull .thumImgGroup .imgInfo {max-width:100px; right:16px; top:10px;}
    .cptForumFull .thumImgGroup .imgInfo h4 {font-size: 12px; letter-spacing: -0.16px;}
    .cptForumFull .thumTitle {height: 24px; margin-top:8px;}
    .cptForumFull .thumTitle h4 {height: 24px; font-size: 14px;}
    .cptForumFull .thumDate {margin-top:2px;}
    .cptForumFull .thumDate .schedule > dt {height:20px; font-size: 12px; line-height: 1.67; letter-spacing: -0.25px;}
    .cptForumFull .thumDate .schedule > dd {height:20px; font-size: 12px; line-height: 1.67; letter-spacing: -0.25px;}
    .cptForumFull .thumDate .schedule > dd .mHide {display:none;}
}
