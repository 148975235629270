/* process card item */
.cptThumVsl {
  width: 100%;
  min-width: 212px;
}
.cptThumVsl .thumLink {
  width: 100%;
  height: 100%;
  display: block;
}
.cptThumVsl .thumImgGroup {
  width: 100%;
  background: #f2f2f2;
  position: relative;
}
.cptThumVsl .thumImgGroup .imgBox {
  width: 100%;
  font-size: 0;
}
.cptThumVsl .thumImgGroup .imgBox img {
  width: 100%;
}
.cptThumVsl .thumImgGroup .imgInfo {
  width: 100%;
  height: 40px;
  position: relative;
}
.cptThumVsl .thumImgGroup .imgInfo .badgeBox {
  font-size: 0;
  position: absolute;
  left: 0;
  bottom: 0;
}
.cptThumVsl .thumImgGroup .imgInfo .badgeBlue {
  height: 24px;
  padding: 2px 10px 0 10px;
  font-family: "Rubik";
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.16px;
  color: #ffffff;
  vertical-align: top;
  background-color: #3c89f9;
  display: inline-block;
}
.cptThumVsl .thumImgGroup .imgInfo .badgeBlack {
  height: 24px;
  padding: 2px 10px 0 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.16px;
  color: #ffffff;
  vertical-align: top;
  background-color: #000000;
  display: inline-block;
  box-sizing: border-box;
}

.cptThumVsl .thumContGroup {
  width: 100%;
}
.cptThumVsl .thumTitle {
  height: 24px;
  margin-top: 16px;
}
.cptThumVsl .thumTitle h4 {
  width: 100%;
  height: 24px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  letter-spacing: -0.29px;
  color: #ffffff;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (min-width: 100px) and (max-width: 1279px) {
}
@media screen and (min-width: 100px) and (max-width: 1023px) {
  .cptThumVsl {
    width: 100%;
    min-width: auto;
  }
  .cptThumVsl .thumImgGroup .imgInfo {
    height: 24px;
  }
  .swiper-slide > .cptThumVsl .thumImgGroup .badgeBox {
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;
  }
  .swiper-slide > .cptThumVsl .thumContGroup {
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;
  }
  .swiper-slide.swiper-slide-active > .cptThumVsl .thumImgGroup .badgeBox {
    visibility: visible;
    opacity: 1;
  }
  .swiper-slide.swiper-slide-active > .cptThumVsl .thumContGroup {
    visibility: visible;
    opacity: 1;
  }
}

@media screen and (min-width: 100px) and (max-width: 640px) {
  /* 240715 메인 키비주얼 수강/대기 과정 수정 */
  .cptThumVsl .thumImgGroup .imgBox img {
    width: 100% !important;
    height: 78px !important;
  }
  .cptThumVsl .thumImgGroup .imgInfo {
    height: 22px;
  }

  .cptThumVsl .thumImgGroup .imgInfo .badgeBlue {
    height: 22px;
    font-size: 10px;
  }
  .cptThumVsl .thumImgGroup .imgInfo .badgeBlack {
    height: 22px;
    font-size: 10px;
  }
  .cptThumVsl .thumTitle {
    margin-top: 8px;
  }

  .cptThumVsl .thumTitle h4 {
    height: 21px;
    font-size: 12px;
  }
}
